import Link from "next/link";
import {useAccount, useDisconnect} from "wagmi";
import React, {useEffect} from "react";
import {CHAIN_ID, PERPS_VIEW} from "@/util/constants";
import {useRouter} from "next/router";
import classNames from "classnames";
import {useWhitelist} from "@/components/whitelist/useWhitelist";
import {Modal, useModal} from "@/components/Modal";
import {SearchBar} from "@/components/SearchBar";
import {UserHeaderButton} from "@/components/Header/UserHeaderButton";
import {ConnectWallet} from "@/components/ConnectWallet";
import {SignAgreement} from "@/components/whitelist/SignAgreement";
import {useScreen} from "@/util/useScreen";
import {GiHamburgerMenu} from "react-icons/gi";
import {AppDropdown} from "@/components/Header/AppDropdown";
import {useHasMounted} from "@/hooks/useHasMounted";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {InfoMessageBanner} from "@/components/Perps/InfoMessageBanner";
import {useMobileMenu} from "@/contexts/MobileMenuContext";
import {useHeaderLinks} from "@/hooks/useHeaderLinks";
import {useCustomHeaderContent} from "@/contexts/CustomHeaderContentContext";
import {Button, ButtonType} from "@/components/Button";

// Header height is controlled by the theme in tailwind.config.js
export const Header2 = () => {
  const isDesktop = useScreen('md');
  const isPerpsView = PERPS_VIEW;
  const { address, chain } = useAccount();

  const links = useHeaderLinks();

  const router = useRouter();
  const whitelist = useWhitelist();
  const signatureModal = useModal();
  const { disconnect } = useDisconnect();
  const hasMounted = useHasMounted();
  const mobileMenu = useMobileMenu();
  const {customContent} = useCustomHeaderContent();

  useEffect(() => {
    if (address && whitelist.data === "NEEDS_TO_SIGN" && !signatureModal.modalVisible) {
      signatureModal.show();
    }
  }, [whitelist.data, address, signatureModal.modalVisible]);

  const onCancel = async () => {
    if (address) {
      await disconnect();
    }
    signatureModal.hide();
  }

  const onSuccess = async () => {
    await whitelist.refetch();
    signatureModal.hide();
  }
  const renderWallet = (className: string) => {
    return (
      <div className={className}>
        {hasMounted && ((!address || chain?.id !== CHAIN_ID) ? (
          <>
            {
              !isPerpsView &&
              <a
                className="rounded-md text-neutral-content border border-neutral-content m-1 text-sm">
                <SearchBar/>
              </a>
            }
            <ConnectWallet className="!py-[8px]"/>
          </>
        ) : (
          <UserHeaderButton
            address={address}
            itemAClassName="h-[40px] md:h-[50px] px-2 md:px-4 flex items-center hover:bg-neutral-content/20 hover:text-white hover:brightness-125 hover:cursor-pointer"
            className="h-full flex flex-row items-center divide-x-0 md:divide-x divide-neutral-content/20 border-neutral-content/20 text-neutral-content border-l-0 border-r-0 md:border-l  border-y-0 rounded-none"/>
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (router.isReady) {
      const current = links.find(l => l.to === router.pathname);
      if (current && current.disabled) {
        const firstEnabled = links.find(l => !l.disabled);
        if (firstEnabled) {
          router.push(firstEnabled.to);
        }
      }
    }
  }, [router]);

  return (
    <>
      <InfoMessageBanner/>
      <Modal {...signatureModal} controlled={true}>
        <SignAgreement onCancel={onCancel} onSuccess={onSuccess} />
      </Modal>
      {
        isDesktop
          ? (
            <div className="flex flex-row items-center min-h-[44px] md:min-h-[50px] lg:flex-row w-full justify-between md:gap-4 border-b border-neutral-content/20 bg-main-bg relative">
              <div className="w-full md:w-max flex flex-row justify-between md:justify-start items-center gap-2 md:gap-6">
                <div className="md:pl-4 relative flex flex-row gap-2 hover:cursor-pointer">
                  <Link href="/">
                    <img src={`/static/wasabi_logo${isDesktop ? '' : '_small'}.svg`}
                         className="w-[28px] md:w-[100px]"
                         alt="wasabi"/>
                  </Link>
                </div>
                <AppDropdown/>
                {
                  hasMounted &&
                  <ul className="flex flex-row items-center gap-4 md:gap-6">
                    {
                      links
                        .filter(l => !l.disabled && hasMounted && (!l.isAuth || address !== undefined))
                        .map((link) => {
                          const {name, to, id, disabled, color} = link;
                          const itemId = `header_${id}`;
                          return (
                            <div key={itemId}>
                              <Link id={itemId}
                                    href={disabled ? "#" : to}
                                    className={classNames("font-light navbar_link hover:text-white", {
                                      [`text-[${color}]`]: color,
                                      "text-white": !color && to === router.pathname,
                                      "text-neutral-content": !color && to !== router.pathname,
                                    })}>
                                {name}
                              </Link>
                              {
                                disabled &&
                                <ReactTooltip
                                  anchorSelect={`#${itemId}`}
                                  id={`tooltip_${id}`}
                                  className="z-50 max-w-[150px]"
                                  style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
                                >Coming Soon</ReactTooltip>
                              }
                            </div>
                          );
                        })}
                  </ul>
                }
              </div>
              {renderWallet("flex flex-row items-center justify-center h-full p-2 md:p-0 mr-2")}
            </div>)
          : (
            <div className="w-full grid grid-cols-4 grid-rows-1 items-center min-h-[60px] border-b border-neutral-content/20 bg-main-bg">
              <div className="flex px-2">
                <Button buttonType={ButtonType.NEUTRAL} onClick={mobileMenu.show} className="hover:bg-glass-focus !p-2 !border-0">
                  <GiHamburgerMenu size={24}/>
                </Button>
              </div>
              <div className="flex items-center justify-center col-span-2">
                {
                  customContent ||
                  <img src={`/static/wasabi_logo${isDesktop ? '' : '_small'}.svg`}
                       className="w-[28px] md:w-[100px]"
                       alt="wasabi"/>
                }
              </div>
              {renderWallet("flex flex-row items-center justify-end p-2")}
            </div>
          )
      }
    </>
  );
};
